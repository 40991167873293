import React from 'react'
import * as styles from './styles.module.scss'

const Materials = () => {
	const materials = [
		{
			img: '/images/hovezi-kuze@2x.png',
			title: 'Hovězí kůže',
			text:
				'Respektive usně nebo hlazenice. Z české produkce menší rodinné koželužny. Hověziny jsou zpracovány technologiemi šetrnými k životnímu prostředí s využitím výtažků z tropických stromů (quebracho a mimosa).',
			lgClassName: ''
		},
		{
			img: '/images/hovezi-kuze@2x.png',
			title: 'Sedlářské nitě',
			text:
				'Pro přírodní a autentické výrobky využívám klasickou lněnou dratev, pro módní produkty pak italské nylonové, voskované nitě v různých barevných provedeních.',
			lgClassName: 'pt-lg-4'
		},
		{
			img: '/images/vceli-vosk@2x.png',
			title: 'Včelí vosk',
			text:
				'V kombinaci s oleji se jedná o jeden z nejlepších způsobů jak impregnovat a zjemnit kůži. Směs si vyrábím vlastní.',
			lgClassName: 'pt-lg-6'
		},
		{
			img: '/images/lneny-olej@2x.png',
			title: 'Směs přírodních olejů',
			text:
				'Použitím směsi včelího vosku. norkového a paznehtového oleje a lanolinu jsou dodávané výrobky měkké, příjemné na omak s dlouhou trvanlivostí a dobrými vodě-odpudivými vlastnostmi.',
			lgClassName: 'pt-lg-4'
		},
		{
			img: '/images/netoxicke-barvy@2x.png',
			title: 'Trvanlivé barvy',
			text:
				'Kvalitní lihové barvy s trvanlivým pigmentem. Americký výrobce Fiebing s dlouholetou tradicí. Po stabilizování barvy v kůži drží a nešpiní oblečení.',
			lgClassName: ''
		}
	]

	return (
		<section className="container text-center mt-7">
			<h2 className="h1 mb-lg-5 mb-7">Použité materiály</h2>
			<div className="row">
				{materials.map((material, index) => (
					<div
						className={`col-sm-6 col-lg mb-6 ${material.lgClassName}`}
						key={index}
					>
						<img
							src={material.img}
							alt={material.title}
							className={`${styles.img} mx-auto`}
						/>
						<h5 className="text-xl fw-bold mt-5 mb-3">{material.title}</h5>
						<p className="text-light">{material.text}</p>
					</div>
				))}
			</div>
		</section>
	)
}

export default Materials
