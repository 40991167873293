import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const StyledSwiper = styled(Swiper)`
	--swiper-navigation-color: #fff;
	--swiper-pagination-color: #fff;
	--swiper-pagination-bullet-inactive-color: #fff;
	--swiper-pagination-bullet-inactive-opacity: 0.5;

	.swiper-button-prev {
		left: 20px;
	}

	.swiper-button-next {
		right: 20px;
	}

	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 20px;
	}
`
