import React from 'react'

const About = () => {
	return (
		<section className="container text-center mt-7">
			<h2 className="h1 mb-lg-5 mb-7">O mně</h2>
			<p>Lorem ipsum...</p>
		</section>
	)
}

export default About
