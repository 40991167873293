import * as React from 'react'
import { PageProps } from 'gatsby'
import Materials from '../components/Materials'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Gallery from '../components/Gallery'
import About from '../components/About'

const IndexPage = (props: PageProps) => {
	return (
		<>
			<Header />
			<Hero />
			<About />
			<Gallery />
			<Materials />
			<ContactForm />
			<Footer />
		</>
	)
}

export default IndexPage
