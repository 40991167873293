import React from 'react'
import { SwiperSlide } from 'swiper/react'

export interface SlideProps {
	photo: string
	title: string
	description: string
}

const Slide: React.FC<SlideProps> = ({ photo, title, description }) => {
	return (
		<div className="row gx-0">
			<div className="col-md-8">
				<img src={photo} alt={title} className="w-100" />
			</div>
			<div className="col-md-4 bg-secondary">
				<div className="p-8">
					<h2 className="text-white">{title}</h2>
					<p className="text-white">{description}</p>
				</div>
			</div>
		</div>
	)
}

export default Slide
