import React from 'react'
import 'lightgallery.js/dist/css/lightgallery.css'
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery'
import * as styles from './styles.module.scss'

const images = ['01.jpg', '02.jpg', '03.jpg', '04.jpg']

const Gallery = () => {
	return (
		<LightgalleryProvider>
			<section className="container text-center mt-7">
				<h2 className="h1 mb-lg-5 mb-7">Galerie produktů</h2>
				<div className="row">
					{images.map((image, index) => (
						<div className="col-6 col-md-4 col-xl-3 d-flex mb-4" key={index}>
							<LightgalleryItem
								src={`/images/gallery/${image}`}
								group="gallery"
								itemClassName="w-100"
							>
								<a
									href={`/images/gallery/${image}`}
									onClick={(event) => event.preventDefault()}
									className={styles.imgContainer}
								>
									<img
										src={`/images/gallery/${image}`}
										className={`${styles.img} img-thumbnail object-fit-cover`}
										alt=""
									/>
								</a>
							</LightgalleryItem>
						</div>
					))}
				</div>
			</section>
		</LightgalleryProvider>
	)
}

export default Gallery
