import React, { useRef, useState } from 'react'
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import * as styles from './styles.module.scss'
import { Values } from './Values'
import successIcon from '../../images/success.svg'
import errorIcon from '../../images/error.svg'

const fields = [
	{
		name: 'name',
		label: 'Vaše jméno'
	},
	{
		name: 'phone',
		label: 'Telefon'
	},
	{
		name: 'email',
		label: 'E-mail'
	}
]

const successMessage = 'Děkuji za odeslání formuláře. Brzy se vám ozvu.'
const errorMessage =
	'Nastala neočekávaná chyba. Prosím použijte pro kontakt e-mail nebo telefon. Děkuji za pochopení.'

const ContactForm = () => {
	const [submitting, setSubmitting] = useState(false)
	const [submitMessage, setSubmitMessage] = useState<string>()
	const [submitSuccess, setSubmitSuccess] = useState(false)

	const onSubmit = async (
		values: Values,
		formikHelpers: FormikHelpers<Values>
	): Promise<void> => {
		setSubmitting(true)

		try {
			const response = await fetch('/api/submitForm', {
				method: 'POST',
				body: JSON.stringify(values)
			})

			if (response.status === 200) {
				setSubmitMessage(successMessage)
				setSubmitSuccess(true)
			} else {
				setSubmitMessage(errorMessage)
				setSubmitSuccess(false)
			}
		} catch (error) {
			setSubmitMessage(errorMessage)
			setSubmitSuccess(false)
		}

		setSubmitting(false)
	}

	return (
		<section className="bg-secondary py-6 position-relative">
			{submitMessage && (
				<div
					className={`position-absolute ${
						submitSuccess ? 'bg-success' : 'bg-secondary'
					} ${styles.submitOverlay}`}
				>
					<div className="container h-100">
						<div className="d-flex align-items-center justify-content-center h-100 text-center flex-column">
							<img src={submitSuccess ? successIcon : errorIcon} alt="" />
							<h3 className="display-4 text-white mt-6">{submitMessage}</h3>
						</div>
					</div>
				</div>
			)}
			<div className="container maxw-lg">
				<Formik
					initialValues={{
						name: '',
						phone: '',
						email: ''
					}}
					onSubmit={onSubmit}
					validationSchema={Yup.object({
						name: Yup.string().required('Prosím vyplňte vaše jméno.'),
						phone: Yup.string().required(
							'Prosím vyplňte vaše telefonní číslo.'
						),
						email: Yup.string()
							.email('Zadejte prosím platný e-mail.')
							.required('Prosím vyplňte váš e-mail.')
					})}
				>
					{({ errors, touched }) => (
						<Form>
							<h2 className="text-white text-center h3 mb-5">
								Chcete si něco <strong>objednat</strong> nebo se na cokoliv{' '}
								<strong>zeptat</strong>?
							</h2>
							<div className="row">
								{fields.map((field, index) => (
									<div className="col-md-4 mb-4" key={index}>
										<label className="text-white mb-2">{field.label}</label>
										<Field
											type="text"
											name={field.name}
											className={`form-control form-control-lg ${styles.input}`}
										/>
										{errors[field.name as keyof Values] &&
											touched[field.name as keyof Values] && (
												<div className="d-inline-block alert alert-secondary py-2 mt-2">
													<ErrorMessage name={field.name} />
												</div>
											)}
									</div>
								))}
							</div>
							<label className="text-white mb-2">Vaše zpráva</label>
							<textarea
								className={`form-control mb-5 ${styles.input}`}
								rows={5}
							></textarea>
							<button
								className="btn btn-dark btn-lg"
								disabled={submitting}
								type="submit"
							>
								{submitting ? 'Odesílání...' : 'Odeslat'}
							</button>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	)
}

export default ContactForm
