import React from 'react'

import logo from '../../images/logo.svg'

const Header = () => {
	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-white">
			<div className="container justify-content-center my-5">
				<a className="navbar-brand" href="#">
					<img src={logo} alt="Entiro leathercrafting" />
				</a>
			</div>
		</nav>
	)
}

export default Header
