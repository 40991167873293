import React from 'react'

import igLogo from '../../images/ig.svg'

const Footer = () => {
	const colClassName = 'col-12 col-sm-6 col-md-auto h1 text-center my-2'

	return (
		<footer className="bg-dark py-6 text-white">
			<div className="container">
				<div className="row justify-content-around">
					<div className={colClassName}>Jan Němec</div>
					<div className={colClassName}>
						<a href="mailto:ahoj@entiro.cz" className="text-white">
							ahoj@entiro.cz
						</a>
					</div>
					<div className={colClassName}>721&nbsp;966&nbsp;675</div>
					<div className={colClassName}>
						<a
							href="https://www.instagram.com/life.with.barnabas/"
							target="_blank"
						>
							<img src={igLogo} alt="Instagram" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
