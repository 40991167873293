import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import Slide, { SlideProps } from './Slide'
import { StyledSwiper } from './styles'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const items: SlideProps[] = [
	{
		photo: '/images/hero/01.png',
		title: 'Opasek',
		description: 'Lorem ipsum'
	},
	{
		photo: '/images/hero/01.png',
		title: 'Minimalistická peněženka',
		description: 'Lorem ipsum'
	},
	{
		photo: '/images/hero/01.png',
		title: 'Miska',
		description: 'Lorem ipsum'
	},
	{
		photo: '/images/hero/01.png',
		title: 'Kabelka - psaníčko',
		description: 'Lorem ipsum'
	}
]

const Header = () => {
	return (
		<StyledSwiper
			modules={[Pagination, Navigation]}
			navigation
			pagination={{ clickable: true }}
		>
			{items.map((slide, index) => (
				<SwiperSlide key={index}>
					<Slide {...slide} />
				</SwiperSlide>
			))}
		</StyledSwiper>
	)
}

export default Header
